.qanda-text_ques {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  border: solid #bababa;
  border-top-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  color: #333;
  text-decoration: none;
  background: none;
  text-align: left;
  font-size: 1rem;
  cursor: pointer;
}

.quesTxt {
  display: inline-block;
  margin-left: 20px;
}

.qanda-text_ans {
  display: block;
  max-height: 0;
  width: 100%;
  color: #353535;
  background-color: #e2e2e2;
  overflow: hidden;
  transition: all 0.3s ease;
}

.qanda-text_ans-content {
  display: block;
  padding: 8px 75px 25px 55px;
}
