.header-mobile-content {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #ffde59;
}

.header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-mobile--bars-icon {
  border: none;
  background: none;
  cursor: pointer;
}

.header-mobile--bars-icon i {
  margin-top: 2px;
  color: #343434;
  font-size: 1.8rem;
}

.header-mobile-sidebar {
  position: fixed;
  width: 350px;
  max-width: 85%;
  left: -350px;
  top: 0;
  bottom: 0;
  z-index: 9999;
  background: #fff;
  box-shadow: 0 0 5px rgb(0 0 0 / 30%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.35s;
}

.header-mobile-sidebar.open {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.header-mobile-sidebar_close-sidebar {
  background: #fff;
  position: absolute;
  left: 100%;
  top: 100px;
  z-index: 999;
  font-size: 1.1rem;
  text-align: center;
  line-height: 45px;
  border: 1px solid #e9e9e9;
  box-shadow: 2px 3px 3px rgb(0 0 0 / 10%);
}

.header-mobile-sidebar_close-sidebar button {
  display: inline-block;
  width: 45px;
  height: 45px;
  color: #11161f;
  border: none;
  background: none;
  font-size: 1.2rem;
  cursor: pointer;
}

.header-mobile-sidebar_menu-container {
  height: 100%;
  padding: 20px;
}

.mobile-main-menu {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style: none;
}

.mobile-main-menu li {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: block;
  width: 100%;
  padding: 0;
}

.mobile-main-menu li a {
  display: block;
  padding: 12px 0;
  color: #11161f;
  text-decoration: none;
  font-weight: bold;
}

.header-mobile-content .bookWithUsBtn-container {
  margin-right: 20px;
}

.header-mobile-content .bookWithUsBtn-container a.btn {
  background-color: #fff;
  color: #333;
  font-size: 0.8rem;
}

.header-mobile-content .bookWithUsBtn-container a.btn:hover,
.header-mobile-content.bookWithUsBtn-container a.btn:focus {
  color: #fff;
  background-color: #4375f4;
}

@media only screen and (min-width: 800px) {
  .header-mobile-content {
    padding: 10px 30px;
  }
}

@media only screen and (min-width: 1025px) {
  .header-mobile {
    display: none;
  }
}
