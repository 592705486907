html {
  scroll-behavior: smooth;
}

:root {
  --bs-stroke: 0.0625rem;
  --bs-color: #241c15;
  --brand-blue: #4375f4;
  --brand-gold: #fdcb34;
}

body {
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.btn {
  display: block;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 3px;
  font-weight: 600;
  border: none;
  transform: all 0.3s ease;
}
