.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-alignItems-flexEnd {
  align-items: flex-end;
}

.flex-justifyContent-start {
  justify-content: start;
}

.flex-justifyContent-center {
  justify-content: center;
}

.bookWithUs-items {
  width: 100%;
}

.flex-item {
  box-sizing: border-box;
  margin: 0 0 10px 0;
  padding: 0;
  box-shadow: 0px 0px 7px rgb(0 0 0 / 20%);
  flex-direction: column;
}

.item-text {
  position: relative;
  width: 100%;
  padding: 28px 50px 28px 28px;
}

.item-text h3 {
  margin-bottom: 10px;
}

.item-text p {
  margin: 0;
  line-height: 1.5rem;
}

.index-num {
  position: absolute;
  left: -18px;
  top: 20px;
  background-color: #fff;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #010233;
  letter-spacing: -0.1px;
  height: 36px;
  width: 36px;
  font-size: 1.5rem;
}

.item-btn_container {
  width: 100%;
  align-items: flex-end;
  padding: 0 20px 20px;
}

.bookWithUs-items a.btn {
  width: 100%;
  background-color: #4375f4;
  color: #fff;
  font-size: 0.9rem;
  text-align: center;
  white-space: nowrap;
}

@media only screen and (min-width: 768px) {
  .flex-item {
    flex-direction: row;
  }

  .item-text {
    width: 70%;
  }

  .item-btn_container {
    padding: 0 20px 40px;
    flex: 1;
    justify-content: center;
  }
}

@media only screen and (min-width: 1025px) {
  .item-text {
    width: 80%;
  }

  .item-btn_container {
  }
}
