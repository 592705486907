.topbar-mobile--social li {
  list-style: none;
  margin-right: 12px;
  font-size: 1.2rem;
}

.topbar-mobile--social li a i {
  color: #343434;
}

.header-top_container--social li {
  list-style: none;
  margin-right: 20px;
}

.header-top_container--social li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #737373;
  font-weight: bold;
  transition: all 0.35s;
}

.header-top_container--social li a span.header-social-icons i {
  color: #737373;
  width: 1.2em;
  margin-right: 3px;
  font-size: 1.2rem;
  transition: all 0.35s;
}

.header-top_container--social li a:hover,
.header-top_container--social li a:hover span.header-social-icons svg {
  color: #343434;
}

.copywrite_container--social li {
  display: inline-block;
  background-color: #eff1f6;
  margin: 0 10px 0 0;
  padding: 0.7rem 0.9rem;
}

.copywrite_container--social-icons i {
  color: #717580;
}
