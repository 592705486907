.content-wrapper {
  display: inline-block;
  width: 100%;
  margin: 1rem 0 5rem;
  min-height: 400px;
}

.section-container {
  display: flex;
  flex-direction: column;
}

.section-lookingFor {
  margin: 3rem 0 0;
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 1025px) {
  .section-wrapper {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
  }
}
