.tncEvents {
  width: 100%;
  display: grid;
  grid-template-areas:
    "event1"
    "event2"
    "event3";
  grid-template-columns: 1fr;
}

.event-container {
  display: flex;
  flex-direction: column;
}

.event1 {
  width: 100%;
  grid-area: event1;
  background-color: #d99536;
}

.event2 {
  width: 100%;
  grid-area: event2;
  background-color: #e7b75f;
}

.event3 {
  width: 100%;
  grid-area: event3;
  background-color: #fdf8ea;
}

.event-image-container {
  position: relative;
  width: 100%;
  display: block;
  padding: 1rem;
  box-sizing: border-box;
  aspect-ratio: 0.8;
  overflow: hidden;
  z-index: 1;
  text-decoration: none;
  color: #000;
}

.event-image-container h2 {
  position: relative;
  z-index: 10;
  font-size: 1.75rem;
}

.event-image {
  margin: 0;
  padding: 0;
}

.event-image picture {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
}

.event-image img {
  max-width: 100%;
  height: auto;
}

.eventDetails {
  flex: auto;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 1rem;
  line-height: 1.75rem;
}

.eventLearnMore-container {
  display: flex;
  margin-top: 1.5rem;
}

.eventLearnMore {
  display: inline-block;
  text-decoration: none;
  background-color: rgba(36, 28, 21, 0);
  color: #000;
  border-radius: 2em;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 0.75rem 1.5rem;
  -webkit-box-shadow: 0 0 0 var(--bs-stroke, 0) var(--bs-color);
  box-shadow: 0 0 0 var(--bs-stroke, 0) var(--bs-color);
  -webkit-transform: translateY(0);
  transform: translateY(0);
  position: relative;
}

.eventLearnMore:before {
  content: "";
  border-radius: var();
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
}

/********** Event Details Form **********/
.event-info-form_container {
  width: 100%;
  margin-top: 4rem;
  padding: 4rem 0 5rem;
  background-color: #4375f4;
}

.event-info-form_text {
  color: #fff;
  text-align: center;
}

.event-info-form_text h3 {
  margin-bottom: 3rem;
}

.form-grid {
  width: 100%;
  display: grid;
  gap: 6px;
  grid-template-areas:
    "fname"
    "femail"
    "fevent"
    "fsubmit";
  grid-template-columns: 1fr;
}

.form-field_name {
  grid-area: fname;
}

.form-field_email {
  grid-area: femail;
}

.form-field_event-dropdown {
  grid-area: fevent;
}

.form-field_submit-btn {
  grid-area: fsubmit;
}

.select-event {
  width: 100%;
  height: 52px;
  padding: 0 30px 0 15px;
  margin-bottom: 2px;
  font-size: 1rem;
  box-shadow: none;
  background: #f0f3f6;
  border: none;
  border-radius: 4px 0px 0px 4px;
  line-height: 50px;
  color: #717580;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;
}

.submit-event-option {
  position: relative;
  width: 100%;
  border-radius: 4px;
  height: 52px;
  background-color: #ffde59;
  color: #343434;
  border: none;
  box-shadow: none;
  outline: none;
  font-size: 1rem;
}

.submit-event-option i {
  margin-right: 6px;
}

.sent,
.error {
  text-align: center;
  margin-bottom: 1rem;
  color: #08b308;
  transition: 0.4s cubic-bezier(0.5, 0.05, 0.155, 0.99);
}
.error {
  color: #e40b00;
}

@media only screen and (min-width: 610px) {
  .tncEvents {
    grid-template-areas: "event1 event2 event3";
    grid-template-columns: repeat(3, 1fr);
  }

  .event-image picture {
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }

  .event-image picture img {
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }

  .event-container:hover .event-image picture,
  .event-container:hover .event-image picture img {
    transform: scale(1.05);
  }

  .eventLearnMore {
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7),
      -webkit-box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7);
    transition: transform 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7),
      box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7),
      -webkit-transform 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7),
      -webkit-box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7);
  }

  .eventLearnMore:before {
    -webkit-transition: bottom 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7);
    transition: bottom 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7);
  }

  .eventLearnMore:hover {
    -webkit-box-shadow: 0 0 0 var(--bs-stroke, 0) var(--bs-color),
      0 var(--animation-depth, 0.375em) 0 0 var(--bs-color);
    box-shadow: 0 0 0 var(--bs-stroke, 0) var(--bs-color),
      0 var(--animation-depth, 0.375em) 0 0 var(--bs-color);
    transform: translateY(calc(-1 * var(--animation-depth, 0.375em)));
  }

  .eventLearnMore:hover:before {
    bottom: calc(-1 * var(--animation-depth, 0.375em) * 1.1);
  }
}

@media only screen and (min-width: 610px) {
  /********** Event Details Form **********/
  .form-grid {
    grid-template-areas:
      "fname femail"
      "fevent fsubmit";
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: 1025px) {
  .eventDetails {
    padding: 2.5rem;
  }

  /********** Event Details Form **********/
  .form-grid {
    grid-template-areas: "fname femail fevent fsubmit";
    grid-template-columns: repeat(4, 1fr);
  }
}
