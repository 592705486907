.footer {
  padding: 80px 0;
  background-color: #ffde59;
}

.footer_container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
}

.footer_container--col1,
.footer_container--col2,
.footer_container--col3,
.footer_container--col4 {
  width: 100%;
}

.footer_container--col1-text {
  margin-bottom: 32px;
  line-height: 32px;
}

.footer_container--col-wrapper {
  margin-top: 50px;
}

.footer_container--h3 {
  margin-bottom: 30px;
}

.footer_container--footer-menu {
  list-style-type: none;
}

.footer_container--footer-menu li a {
  width: 100%;
  text-decoration: none;
  font-weight: 500;
  color: #343434;
  line-height: 1.8rem;
}

.copywrite {
  text-align: center;
  padding: 18px 0;
}

.copywrite_container {
  padding: 0 15px;
}

.copywrite p {
  line-height: 1.86rem;
}

.copywrite_container--social {
  margin-top: 10px;
}

.footer-form .input-name {
  margin-bottom: 8px;
}

.copywrite_container--text a {
  margin-left: 5px;
}

@media only screen and (min-width: 768px) {
  .footer_container {
    max-width: 1200px;
    flex-direction: row;
    justify-content: space-between;
  }

  .footer_container--col1 {
    width: 40%;
    padding-right: 60px;
  }

  .footer_container--col2 {
    width: 20%;
  }

  .footer_container--col3 {
    padding-left: 40px;
    width: 40%;
  }

  .footer_container--col4 {
    width: 32%;
  }

  .footer_container--col-wrapper {
    margin-top: 0px;
  }

  .copywrite_container {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
  }
}
