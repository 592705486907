.topbar-mobile {
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
  border-bottom: 1px solid #737373;
  background-color: #ffde59;
}

.topbar-mobile--social {
  display: flex;
}

@media only screen and (min-width: 800px) {
  .topbar-mobile {
    padding: 5px 30px;
  }
}
