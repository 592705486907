/************* Services Styles *************/

.section-wrapper {
  padding: 0 20px;
}

.section-wrapper.section-ourServices h2 {
  color: var(--brand-blue);
}

.heading-container {
  padding: 0 15px;
}

.services-container {
  margin-top: 2rem;
  padding: 0 15px;
  display: grid;
  gap: 1rem;
  grid-template-areas:
    "one"
    "two"
    "three"
    "four"
    "five";
}

.services-item-1 {
  grid-area: one;
}

.services-item-2 {
  grid-area: two;
}

.services-item-3 {
  grid-area: three;
}

.services-item-4 {
  grid-area: four;
}

.services-item-5 {
  grid-area: five;
}

.grid-item {
  width: 100%;
  margin-bottom: 25px;
}

@media only screen and (min-width: 768px) {
  /************* Services Styles *************/

  .services-container {
    grid-template-areas:
      "one one two two three three"
      ". four four five five .";
  }

  .heading-container {
    margin-top: 5rem;
  }
}
