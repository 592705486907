.header-main {
  position: relative;
  width: 100%;
  display: none;
}

.header.homePg .header-main {
  position: absolute;
}

.header-top {
  width: 100%;
  padding: 0 40px;
  background-color: #ffcc00;
}

.subPg .header-top {
  background-color: #fff;
}

.header-top_container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
}

.header-top_container--left {
  width: 50%;
}

.header-top_container--social {
  display: flex;
  height: 40px;
  align-items: center;
}

.header-social-text {
  font-size: 0.9rem;
}

.header-content {
  width: 100%;
  padding: 0 40px;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #3434343f;
  transition: all 0.1s;
}

.header-content_container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: start;
  position: relative;
}

.header-main.isFixed > .header-content {
  background: #ffde59bb;
  position: fixed;
  width: 100%;
  z-index: 999;
  left: 0;
  top: 0;
  box-shadow: 2px 3px 3px rgb(0 0 0 /10%);
}

.header-main.isFixed.subPg > .header-content {
  background: #fff;
}

.header-content_container--right {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header-content_container--main-menu {
  display: flex;
}

.header-content_container--main-menu {
  list-style: none;
}

.header-content_container--main-menu li a {
  display: inline-block;
  padding: 41px 15px 31px;
  color: #343434;
  text-decoration: none;
  font-weight: bold;
}

.header-content_container--main-menu li {
  position: relative;
  display: inline-block;
}

.header-content_container--main-menu > li:after {
  content: "";
  position: absolute;
  background: #343434;
  width: 100%;
  height: 3px;
  top: -1px;
  left: 0;
  z-index: 11;
  opacity: 0;
  transition: all 0.35s;
}

.header-content_container--main-menu > li:hover:after,
.header-content_container--main-menu > li.activePg:after {
  opacity: 1;
}

.bookWithUsBtn-container {
  display: flex;
  align-items: center;
}

.bookWithUsBtn-container a.btn {
  background-color: #4375f4;
  color: #fff;
  font-size: 0.9rem;
}

.bookWithUsBtn-container a.btn:hover,
.bookWithUsBtn-container a.btn:focus {
  -webkit-box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
  box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
  color: #333;
  background-color: #ffde59;
}

@media only screen and (min-width: 1025px) {
  .header-main {
    display: block;
  }
}
